.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.btn-purple {
  background-color: #A435F0;
  border-color: #A435F0;
  color: #ffffff; /* You may want to adjust text color for better contrast */
}

.btn-purple:hover {
  background-color: #a851e2; /* Adjust hover color as needed */
  border-color: #bcacc7;
}

.btn-purple:active {
  border-width: 4px; /* Adjust border width as needed */
  border-color: #A435F0; /* Adjust border color as needed */
}